.c-card {
  border: 1px solid rgba(128, 128, 128, 0.603);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
  width: 50%;
  -webkit-box-shadow: 0 0 4px rgb(161, 158, 158);
  box-shadow: 0 0 6px rgb(161, 158, 158);
  &__body {
    display: flex;
    flex-direction: column;
    text-align: left;
    font-weight: 500;
  }
  &__img {
    width: 10%;
    height: 85%;
    transition: all ease-in-out 0.3s;
    margin-right: 10%;
    object-fit: contain;

    &:hover {
      transform: scale(1.15);
      cursor: pointer;
      filter: saturate(200%);
    }
  }
  &__trash {
    width: 24px;
    height: 24px;
    position: relative;
    bottom: 16%;
    left: 98%;

    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  }
  &__name {
    font-weight: bold;
    font-size: 1.5rem;
  }
  &__tagline {
    font-style: italic;
  }
  &__description {
    width: 98%;
    font-weight: 400;
  }
  &__properties {
    display: flex;
    flex-wrap: wrap;
    gap: 16%;
    &__title {
      text-decoration: underline;
    }
    &__value {
      text-align: center;
    }
  }
}

.c-link {
  color: black;
  text-decoration: none;
  display: flex;
}

@media (max-width: 1500px) {
  .c-card {
    width: 80%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__img {
      width: 20%;
      margin: 10%;
    }
    &__trash {
      bottom: 60%;
      left: 45%;
    }
    &__body {
      text-align: center;
    }

    &__properties {
      flex-wrap: wrap;

      justify-content: center;
    }
    &__description {
      height: 11vh;
      overflow-x: hidden;
    }
  }
  .c-link {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
