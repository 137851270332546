.c-beerDetail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px;
  width: 100%;
  height: 100vh;

  &__img {
    height: 60vh;
  }
  &__description {
    font-style: italic;
    width: 50%;
  }
  &__details {
    display: flex;
    &__title {
      width: 20%;
    }
  }
  &__food {
    width: 40%;
    display: flex;
  }
}

.cta {
  border: none;
  background: none;
  position: absolute;
  top: 5%;
  right: 10%;
  cursor: pointer;
}

.cta span {
  padding-bottom: 7px;
  letter-spacing: 4px;
  font-size: 10px;
  padding-right: 15px;
  text-transform: uppercase;
}

.cta svg {
  transform: translateX(-8px);
  transition: all 0.3s ease;
}

.cta:hover svg {
  transform: translateX(0);
}

.cta:active svg {
  transform: scale(0.9);
}

.hover-underline-animation {
  position: relative;
  color: black;
  padding-bottom: 20px;
}

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1.5px;
  bottom: 0;
  left: 0;
  background-color: #000000;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.cta:hover .hover-underline-animation:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

@media (max-width: 1000px) {
  .c-beerDetail {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 20px;

    &__img {
      margin-top: 10px;
      height: 50%;
    }
    &__description {
      width: 90%;
      line-height: 12px;
    }
    &__name {
      margin-top: 32px;
      color: rgb(55, 73, 67);
      text-shadow: 0px 0px 1px rgb(55, 73, 67);
    }
    &__food {
      flex-direction: column;
      text-align: center;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }
  .cta {
    right: 10px;
    top: 18px;
  }
}
