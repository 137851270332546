.c-nav {
  position: fixed;
  top: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 7vh;
  background-color: white;
  box-shadow: 0px 2px 1px rgba(128, 128, 128, 0.377);
  z-index: 1;
  &__logo__img {
    margin: 2px 10px;
    height: 6vh;
    width: auto;
  }
  &__item {
    margin: 0 24px;
    font-weight: 500;
    cursor: pointer;
    text-transform: uppercase;
    color: rgba(52, 53, 52, 0.74);
  }
  &__content {
    display: flex;
  }
  &__link {
    color: black;
    text-decoration: none;
  }
}

@media (max-width: 768px) {
  .c-nav {
    width: 100vw;
    justify-content: space-between;
    &__item {
      font-size: 12px;
    }
  }
}
